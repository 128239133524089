import ensenadaData from "../config/ensenada-full.json";
import valleData from "../config/valle-full.json";
import axios from "axios";
import CONFIG from "../config/config.json";

const getInitialData = () => {
  localStorage.setItem("ensenadaData", JSON.stringify(ensenadaData));
  localStorage.setItem("valleData", JSON.stringify(valleData));
};

const getEnsenadaData = () => {
  return localStorage.getItem("ensenadaData");
};

const getValleData = () => {
  return localStorage.getItem("valleData");
};

const search = (procedures, value) => {
  var result = [];

  /* case empty search */
  if (value === "") {
    return procedures;
  }

  procedures.map((procedure) => {
    if (
      procedure.description.includes(value) ||
      procedure.name.includes(value) ||
      procedure.dependency.name.includes(value)
    ) {
      result.push(procedure);
    }
    return -1;
  });

  return result;
};

const getBannerData = async () => {

  try {
    const response = await axios.get(CONFIG.env.URL_BANNER);
    return response.data.list_data;
  } catch (error) {
    console.error("Error al obtener los datos del banner", error);
  }

};

const InformationPortalService = {
  getInitialData,
  search,
  getEnsenadaData,
  getValleData,
  getBannerData,
};

export default InformationPortalService;
