/**
 * @module - LoadingButton
 * @description This component displays a loading button to the user and has three possible ways to use it.
 */

/**
 * @function 
 * @description - La primera variante toma todo el ancho y alto de la pantalla para el contenedor padre del componente, 
 * recomendado utilizar cuando quieres que el usuario unicamente mire este componente mientras se realiza una carga.
 */
const LoadingButton = () => {
  // it will return an element depending on its waiting state
  return (
    <div className="container-fluid vh-100 d-flex flex-wrap justify-content-center align-content-center">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <br />
      <p className="text-black w-100 text-center mt-2">Cargando...</p>
    </div>
  );
};

/**
 * @function
 * @description - Variante de LoadingButton a diferencia que el "height" del componente toma solo lo necesario.
 */
export const LoadingButtonV2 = () => {
  return (
    <div
      id="LoadingButtonV2"
      className="container-fluid d-flex flex-wrap justify-content-center align-content-center"
    >
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <br />
    </div>
  );
};

/**
 * @function
 * @description Una variante del componente LoadingButton, permite indicar donde posicionar el componente
 * @param {string} text - El texto que se mostrara debajo de la figura cargando
 * @param {string} position - La posicion donde se acomodara el componente (left, right, center)
 */
export const LoadingButtonV3 = ({ text, position }) => {
  return (
    <div
      id="LoadingButtonV3"
      className={`container-fluid d-flex flex-nowrap ${
        position === "left" ? "justify-content-start" : ""
      } ${position === "right" ? "justify-content-end" : ""} ${
        position === "center" ? "justify-content-center" : ""
      } align-content-center`}
    >
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <span className="text-black pl-3 text-center mt-2" style={{fontSize: "0.90rem"}}>{text}</span>
    </div>
  );
};

export const LoadingButtonV4 = ({ text, position }) => {
  return (
    <div
      id="LoadingButtonV4"
      className={`d-flex flex-nowrap ml-3 px-4 ${
        position === "left" ? "justify-content-start" : ""
      } ${position === "right" ? "justify-content-end" : ""} ${
        position === "center" ? "justify-content-center" : ""
      } align-content-center`}
    >
      <span className="spinner-border text-primary" role="status">
      </span>

      {text ? (
        <span className="text-black pl-3 text-center mt-2" style={{fontSize: "0.90rem"}}>{text}</span>
      ) : undefined}
    </div>
  );
};

export const LoadingButtonV5 = ({ text, position }) => {
  return (
    <div className={`w-100 py-5`}>
    <div
      id="LoadingButtonV4"
      className={`d-flex flex-nowrap ml-3 px-4 ${
        position === "left" ? "justify-content-start" : ""
      } ${position === "right" ? "justify-content-end" : ""} ${
        position === "center" ? "justify-content-center" : ""
      } align-content-center`}
    >
      <span className="spinner-border text-primary" role="status">
      </span>

    </div>
    <div className="w-100 text-center mt-3">
      {text ? (
          <span className="text-black text-center" style={{fontSize: "0.90rem"}}>{text}</span>
        ) : undefined}
    </div>
    </div>
  );
};

export default LoadingButton;
