import React, { lazy, Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import reportWebVitals from "./reportWebVitals.js";
import LoadingButton from "./components/LoadingButton.js";
import InformationPortalService from "./services/InformationPortalService.js";
import AuthProvider from "./context/AuthContext.js";

//fonts
import "../src/assets/fonts/Seravek/Seravek.ttf";

//styles
import "./assets/css/Index-Color.css";
import "./assets/css/Index-Components.css";
import "./assets/css/Index-Font.css";
import "./assets/css/Formio.css";

const Header = lazy(() => import("./views/MainPage/Sections/HeaderSection.js"));
const BannerEmerging = lazy(() => import("./components/BannerEmerging.js"));

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.FADE,
};

const App = () => {
  const [bannerData, setBannerData] = useState();

  useEffect(() => {
    InformationPortalService.getInitialData();
    InformationPortalService.getBannerData().then((response) => {
      setBannerData(response);
    });
  }, []);

  return (
    <AuthProvider>
      <Suspense fallback={<LoadingButton />} className="poppins-regular">
        <>
          {bannerData === undefined ? (
            false
          ) : bannerData.length === 0 ? (
            <></>
          ) : (
            <BannerEmerging data={bannerData} />
          )}
        </>
        <Router keyLength={12} basename="/">
          <AlertProvider template={AlertTemplate} {...options}>
            <Route to={`${process.env.PUBLIC_URL}/`} component={Header} />
          </AlertProvider>
        </Router>
      </Suspense>
    </AuthProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
reportWebVitals();
