import axios from "axios";
import CONFIG from "../config/config.json";

const fileTypesAvaliables = [
    "jpeg",
    "jpg",
    "png",
    "pdf",
    "vnd.openxmlformats-officedocument.wordprocessingml.document", //docx
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet", //xlsx
    "vnd.ms-excel", //xls
    "vnd.oasis.opendocument.text", //odt
    "msword", //doc
];

const HeadersSchemma = (token = GetStorageServices()) => {
    return {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
    };
};

const SetStorageServices = (token) => {
    localStorage.setItem("token_user", token);
};

const GetStorageServices = () => {
    return localStorage.getItem("token_user");
};

export const CleanStorageServices = () => {
    localStorage.removeItem("token_user");
    localStorage.removeItem("user");
    localStorage.removeItem("type");
    localStorage.removeItem("user_id");
    localStorage.removeItem("closedYW");
};

export const ValidateStatusServices = async (handleLogout, handleToken) => {
    let token = GetStorageServices();

    try {
        const response = await axios.get(CONFIG.env.URL_AUTH + CONFIG.route.AUTH.STATUS + CONFIG.system_type.PORTAL_CIUDADANO, { 
            headers: HeadersSchemma(token) 
        });

        if(response?.data?.token !== undefined) {
            localStorage.setItem("token", response.data.token);
            handleToken(response.data.token);
        }

        let tokenTime = response.data?.formData?.token_date;

        return {status: response.status, token_date: tokenTime, userType: response.data.formData.type, name: response.data.user_name, email: response.data.formData.email};

    } catch (fail) {
        console.log("FAIIIL:",fail);
        debugger;
        CleanStorageServices();
        handleLogout();
        return {status: fail.response.status};
    }
}

export const LoginServices = async (user) => {

    try {
        const response = await axios.post(CONFIG.env.URL_AUTH + CONFIG.route.AUTH.LOGIN, user);
        SetStorageServices(response.data.auth_token);
        localStorage.setItem("type", response.data.type);
        localStorage.setItem("user", response.data.user_name);
        return {result: true, ...response.data};
    } catch (error) {
        console.error("LoginServices:",error);
    }

};

const LogoutServices = async (props) => {
    let token = GetStorageServices();
    if (token !== null) {
        return await axios
            .get(CONFIG.env.URL_AUTH + CONFIG.route.AUTH.LOGOUT, {
                headers: HeadersSchemma(token),
            })
            .then((response) => {
                CleanStorageServices();
                return { message: true, resolve: response };
            })
            .catch((fail) => {
                CleanStorageServices();
                return { message: false, resolve: fail };
            });
    } else {
        return false;
    }
};

const RegisterServices = async (body) => {
    return await axios
        .post(CONFIG.env.URL_AUTH + CONFIG.route.AUTH.REGISTER, body)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
};

const UserUpdateServices = (body) => {
    let token = GetStorageServices();
    return axios
        .patch(CONFIG.env.URL_AUTH + CONFIG.route.AUTH.DATA_UPDATE, body, {
            headers: HeadersSchemma(token),
        })
        .then((response) => {
            if (response.data.token !== undefined) {
                localStorage.setItem("token_user", response.data.token);
            }

            return response;
        });
};

const GetDataUser = async () => {
    let token = localStorage.getItem("token_user");
    return await axios
        .get(CONFIG.env.URL_AUTH + CONFIG.route.AUTH.DATA, {
            headers: HeadersSchemma(token),
        })
        .then((response) => {
            if (response.data.token !== undefined) {
                localStorage.setItem("token_user", response.data.token);
            } else {
            }
            localStorage.setItem(
                "data",
                JSON.stringify(response.data.formData)
            );
            return response.data;
        });
};

const isFileTypeAvaliable = (imgBase64) => {
    if (typeof imgBase64 !== "string") {
        return false;
    }
    const [, type] = imgBase64.split(";")[0].split("/");
    return fileTypesAvaliables.includes(type);
};

const isDocsAvaliables = (docs) => {
    var sizeCount = 0;
    for (const doc of docs)
        for (const oneFile of doc.file) {
            if (!isFileTypeAvaliable(oneFile.url))
                return {
                    result: false,
                    message: "Uno de los archivos no esta permitido.",
                };
            sizeCount += oneFile.size;
            if (sizeCount > 10000000)
                return {
                    result: false,
                    message: "Limite de tamaño de archivos superado.",
                };
        }
    return { result: true };
};

const isFilesAvaliables = (files) => {
    var sizeCount = 0;
    for (const file of files) {
        if (!isFileTypeAvaliable(file.url))
            return {
                result: false,
                message: "Uno de los archivos no esta permitido.",
            };
        sizeCount += file.size;
        if (sizeCount > 10000000)
            return {
                result: false,
                message: "Limite de tamaño de archivos superado.",
            };
    }
    return { result: true };
};

function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }
    return true;
}

const ValidatePassword = async (object) => {

    let token = localStorage.getItem("token_user");

    const request = await axios.get(CONFIG.env.URL_VERIFY_PASS+"?password="+object.password, {
        headers: HeadersSchemma(token),
    });

    const isValidCurrentPassword = request.data.data;

    if(isValidCurrentPassword)
        return true;
    else 
        return false;

};

const AuthServices = {
    HeadersSchemma,
    LoginServices,
    ValidatePassword,
    UserUpdateServices,
    GetDataUser,
    LogoutServices,
    ValidateStatusServices,
    CleanStorageServices,
    RegisterServices,
    GetStorageServices,
    //isFileTypeAvaliable
    isDocsAvaliables,
    shallowEqual,
    isFilesAvaliables,
};

export default AuthServices;
