import React, { useEffect, useState } from 'react'
import { ValidateStatusServices, CleanStorageServices } from '../services/AuthServices';

export const AuthContext = React.createContext();


function AuthProvider({children}) {

  const [isAuthenticated, setIsAuthenticated] = useState(false)
	const [token, setToken] = useState("");
	const [tokenTime, setTokenTime] = useState(0);
	const [userData, setUserData] = useState({
		name: "", 
		email: "", 
		userType: -1
	});

	const handleLogin = (data) => {
		setIsAuthenticated(true);
		setUserData({
			name: data.name,
			email: data.email,
			userType: data.type
		});
	}

	const handleLogout = () => {		
		CleanStorageServices();
		setIsAuthenticated(false);
	}

	const handleToken = (value) => {
		setToken(value);
	}

	const handleTokenTime = (value) => {
		setTokenTime(value);
	}


	//VALIDA SI YA HAY UN USUARIO LOGUEADO
	useEffect(() => {

		const validateToken = async (handleLogout, handleToken) => {

			try {
				if(localStorage.getItem("token_user") !== null){

					const response = await ValidateStatusServices(handleLogout, handleToken);

					if(response.status === 200) {
						setIsAuthenticated(true);
						setUserData({name: response.name, userType: response.userType, email: response.email});
					}

					return true
				} else {
					//NO HAY TOKEN
					return false;
				}
			} catch (error) {
				console.error("Ha ocurrido un error:",error);
			}
		}

		if(!isAuthenticated)
			validateToken(handleLogout, handleToken);


	}, [isAuthenticated, token]); //eslint-disable-line react-hooks/exhaustive-deps


  return (
		<AuthContext.Provider value={{isAuthenticated, handleLogin, handleLogout, handleToken, tokenTime, handleTokenTime, userData}}>
				{children}
		</AuthContext.Provider>
	)
}

export default AuthProvider